@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'react-big-calendar/lib/css/react-big-calendar.css';
@import 'react-toastify/dist/ReactToastify.css';

@layer utilities {
  @variants responsive {
    .scrollbar::-webkit-scrollbar {
      width: 0.5em;
    }

    .handle::-webkit-scrollbar-thumb {
      background: #00dcb9;
      border-radius: 10px;
    }

    .hover::-webkit-scrollbar-thumb:hover {
      background: #cbedfc;
      border-radius: 10px;
    }

    .scrollbar {
      scrollbar-width: 0.5em; /* Firefox */
    }
  }
}

.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  /* position: absolute; */
  width: 100%;
}

.cal {
  border: 0.1px solid #cccac5;
  width: fit-content;
  background-color: #fff;
  width: 55px;
  font-family: Arial, sans-serif;
  border-radius: 11px;
  text-align: center;
  box-shadow: 0px 0px 10px 10px rgb(65, 64, 64) inset;
  box-shadow: 0px -7px 8px -6px #000 inset;
}
.cal .month {
  height: 25px;
  line-height: 30px;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #e40d02;
  color: #fff;
  align-items: center;
  border-radius: 11px 11px 2px 2px;
}
.cal .date {
  font-size: 1.5rem;
  color: #021e46;
  line-height: 25px;
  font-weight: bolder;
  margin-top: 5px;
}
.cal .hour {
  font-size: 0.8rem;
  color: #021e46;
  line-height: 19px;
  font-weight: bold;
}

.trashButton {
  margin-bottom: -20px;
}

@media screen and (max-width: 768px) {
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    border-bottom: 1px solid rgb(204, 200, 200);
    display: block;
    margin-bottom: 0.825em;
  }

  table td {
    font-size: 0.87em;
    text-align: right;
    margin-bottom: 0.825em;
  }

  table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    font-size: 0.25em;
    text-transform: uppercase;
    margin-right: 20px;
    color: #fff;
  }

  table td:last-child {
    border-bottom: 0;
  }
}
/* REact big Calendar Style */

div > .rbc-day-bg {
  @apply bg-white; /*border-1 border-tint-stack-neutral */
}

/* div > .rbc-row-bg {
  @apply border-1 border-tint-stack-neutral;
} */

div > .rbc-today:not(.rbc-header) {
  @apply bg-teal;
}

div > .rbc-off-range-bg,
.rbc-off-range-bg {
  @apply bg-difused-blue;
}

div > .rbc-toolbar {
  @apply grid xs:grid-cols-1 md:grid-cols-3;
}

/* div > .rbc-toolbar > .rbc-btn-group {
  @apply grid grid-cols-4 gap-1;
} */

/* div > .rbc-toolbar > .rbc-toolbar-label {
  @apply text-lg font-bold;
} */

div > .rbc-toolbar > .rbc-btn-group > button {
  @apply bg-white;
}

div > .rbc-toolbar > .rbc-btn-group > button {
  @apply rounded-md !important;
}
div > .rbc-toolbar > .rbc-toolbar-label {
  @apply text-tint-stack-neutral;
}

div > .rbc-month-header > .rbc-header {
  @apply border-l-2 border-atmospheric-white;
}

div > .rbc-month-view {
  @apply border-2 border-atmospheric-white;
}

div > .rbc-header {
  @apply text-right text-tint-stack-neutral;
}

/* div > .rbc-toolbar > .rbc-btn-group > button:first-child {
  @apply -ml-0;
}

div > .rbc-toolbar > .rbc-btn-group > button:last-child {
  @apply -mr-0;
} */

/* div > .rbc-toolbar > .rbc-btn-group > button {
  @apply border-2 rounded-full border-red;
} */

/* div > .rbc-toolbar > .rbc-toolbar-label {
  @apply text-lg font-bold;
}

.rbc-day-bg:nth-child(6),
.rbc-day-bg:last-child {
  @apply bg-pale-gray;
}

.rbc-date-cell:nth-child(6),
.rbc-date-cell:last-child {
  @apply text-gray;
}

div > .rbc-day-bg + .rbc-off-range-bg,
.rbc-off-range-bg {
  @apply bg-light-blue text-light-blue;
}

div > .rbc-today:not(.rbc-header) {
  @apply bg-sky-blue;
}

div > .rbc-header {
  @apply bg-white;
}

div > .rbc-day-slot + .rbc-time-column:last-child,
div > .rbc-day-slot + .rbc-time-column:nth-child(7) {
  @apply bg-pale-gray;
} */

.mdt-checkbox[type='checkbox'] {
  @apply w-6 h-6;
}

.mdt-checkbox[type='checkbox']:after {
  position: relative;
  display: block;
  @apply w-6 h-6 border-4 border-solid;
  content: '';
  background-repeat: no-repeat;
  background-position: center;
}

.mdt-checkbox[type='checkbox']:before {
  border: none;
  content: '';
  @apply bg-white;
  position: relative;
  display: block;
}

.mdt-checkbox-online[type='checkbox']:after {
  @apply border-cobalt;
}

.mdt-checkbox-online[type='checkbox']:checked:after {
  @apply bg-cobalt border-cobalt;
}

.mdt-checkbox-onsite[type='checkbox']:checked:after {
  @apply bg-primary border-primary;
}

.mdt-checkbox-onsite[type='checkbox']:after {
  @apply border-primary;
}
